<template>
	<div>
		<!-- 头部logo -->
		<moveHeadLogo @navigation="navigation"></moveHeadLogo>
		
		<!-- 头部导航 -->
		<moveNavigation :headTitle="headTitle" :headImg="headImg"></moveNavigation>
		
		<div class="ticketing">
			<div v-html="list.bodyContent">
				{{list.bodyContent}}
			</div>
		</div>
		
		<!-- 菜单导航 -->
		<moveMeauDialog :meauValue="navigationvalue" @meauFn="menu"></moveMeauDialog>
		
		<!-- 底部导航 -->
		<moveBottomNavigation></moveBottomNavigation>
	</div>
</template>

<script>
	import { selectContentListById } from '@/api/index.js'
	// 头部组件
	import moveHeadLogo from '@/components/move/moveHeadLogo.vue'
	// 菜单导航
	import moveMeauDialog from '@/components/move/moveMeauDialog.vue'
	// 头部导航
	import moveNavigation from '@/components/move/moveNavigation.vue'
	// 底部导航
	import moveBottomNavigation from '@/components/move/moveBottomNavigation.vue'
	export default
	{
		data()
		{
			return{
				// 头部导航标题
				headTitle:'TICKETING',
				// 头部导航图片
				headImg:'/index/ticketing.png',
				// 导航值
				navigationvalue:false,
				
				list:{}
			}
		},
		components:
		{
			moveHeadLogo,
			moveMeauDialog,
			moveNavigation,
			moveBottomNavigation,
		},
		created()
		{
			this.getList();
		},
		methods:
		{
			// 头部传来的数据
			navigation(v)
			{
				this.navigationvalue=v;
				console.log(this.navigationvalue,'头部传递菜单导航')
			},
			// 遮罩层传回来的数据
			menu(v)
			{
				this.navigationvalue=v;
			},
			getList()
			{
				selectContentListById(
				{
				  chnanelType: 1,
				  isIndex: 0,
				  moduleType: 'MODULE_TICKETING'
				})
				.then((response)=>
				{
					if(response.data.data=='')
					{
						this.$router.push(
						{
							path:'/error',
							item:2,
						})
					}
					else
					{
						this.list=response.data.data[0];
					}
				})
			}
		}
	}
</script>

<style >
	.ticketing{
		width: 96%;
		margin-left: 2%;
	}
	.ticketing img{
		max-width: 100%;
	}
</style>